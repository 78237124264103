<template>
  <div>
    <modal
      className="w-11/12 md:w-443px lg:w-443px flex flex-col rounded"
      ref="paymentModal"
    >
      <h4
        class="text-xl xl:text-20 font-bold mb-10 -mt-2 text-left text-gray-700"
      >
        Repayment
      </h4>
      <div>
        <template v-if="getFormError(form)">
          <div class="alert alert-red-soft -mt-10 mb-10">
            <div class="alert-icon">!</div>
            <div class="text-xs font-normal">
              {{ getFormError(form) }}
            </div>
          </div>
        </template>
        <div class="flex flex-col mb-4">
          <div class="border border-blue-500 p-4 rounded">
            <div class="flex justify-between">
              <h5 class="text-sm text-blue-500">Clear current</h5>
              <h5 class="text-sm text-blue-500">
                {{ amountToPayInCurrency }}
              </h5>
            </div>
          </div>
        </div>
        <div class="w-full flex items-center pt-5 pb-12">
          <div
            class="w-4/12 lg:w-5/12 bg-gray-800"
            style="height: 1px; opacity: 0.04"
          />
          <div
            class="w-4/12 lg:w-3/12 text-sm font-bold text-gray-800 text-center"
          >
            Pay With
          </div>
          <div
            class="w-4/12 lg:w-5/12 bg-gray-800"
            style="height: 1px; opacity: 0.04"
          />
        </div>
        <div class="flex flex-col w-full">
          <div
            class="flex justify-between mb-5 cursor-pointer"
            @click="paymentMethod = 'paystack'"
          >
            <h5
              class="text-14 font-normal text-gray-800 inline-flex"
              :class="
                paymentMethod === 'paystack' ? 'opacity-100' : 'opacity-50'
              "
            >
              <svg
                width="35"
                height="24"
                viewBox="0 0 35 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="35" height="23.3333" rx="3" fill="#242D4F" />
                <circle
                  opacity="0.8"
                  cx="14.5"
                  cy="11.5"
                  r="5.5"
                  fill="white"
                />
                <circle
                  opacity="0.5"
                  cx="20.5"
                  cy="11.5"
                  r="5.5"
                  fill="white"
                />
              </svg>
              <span class="mt-1 ml-2">Card via paystack</span>
            </h5>
            <span
              class="rounded-full px-2 py-2"
              :class="
                paymentMethod === 'paystack'
                  ? 'bg-blue-500'
                  : 'border border-credpal-300'
              "
            >
              <svg
                width="10"
                height="9"
                viewBox="0 0 12 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 1L4.125 8L1 4.81818"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
          <p class="text-10 font-normal text-blue-500 -mt-3 mb-4 text-left">
            A processing fee of ₦{{ processingFee | currency }} applies.
          </p>

          <div
            class="w-full mb-3 bg-gray-800"
            style="height: 1px; opacity: 0.04"
          ></div>
          <div
            class="flex justify-between mb-10 mt-2 cursor-pointer"
            @click="paymentMethod = 'bank_transfer'"
          >
            <h5
              class="text-14 font-normal text-gray-800 inline-flex"
              :class="
                paymentMethod === 'bank_transfer' ? 'opacity-100' : 'opacity-50'
              "
            >
              <svg
                width="35"
                height="24"
                viewBox="0 0 35 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="35" height="24" rx="3" fill="#848A9D" />
                <path
                  d="M23.8143 10.8064L17.7795 6.09634C17.5938 5.96789 17.3617 5.96789 17.176 6.09634L11.1411 10.8064C11.0019 10.9349 10.9555 11.149 11.0483 11.2774C11.0947 11.4487 11.2804 11.5772 11.4661 11.5772H12.8588V15.8591H11.9303C11.6518 15.8591 11.4661 16.0303 11.4661 16.2872V17.5718C11.4661 17.8287 11.6518 18 11.9303 18H23.0716C23.3501 18 23.5358 17.8287 23.5358 17.5718V16.2872C23.5358 16.0303 23.3501 15.8591 23.0716 15.8591H22.1431V11.5772H23.5358C23.7215 11.5772 23.9072 11.4487 23.9536 11.2774C24.0464 11.149 24 10.9349 23.8143 10.8064ZM13.7872 11.5772H15.6441V15.8591H13.7872V11.5772ZM18.4294 15.8591H16.5725V11.5772H18.4294V15.8591ZM22.6073 17.1436H12.3945V16.7154H22.6073V17.1436ZM21.2147 15.8591H19.3578V11.5772H21.2147V15.8591ZM12.7195 10.7208L17.5009 6.99554L22.2824 10.7208H12.7195Z"
                  fill="white"
                />
              </svg>
              <span class="mt-1 ml-2">Bank transfer</span>
            </h5>
            <span
              class="rounded-full px-2 py-2"
              :class="
                paymentMethod === 'bank_transfer'
                  ? 'bg-blue-500'
                  : 'border border-credpal-300'
              "
            >
              <svg
                width="10"
                height="9"
                viewBox="0 0 12 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 1L4.125 8L1 4.81818"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
        </div>
        <div class="text-center mt-10">
          <template v-if="paymentMethod === 'paystack'">
            <paystack
              :amount="amountToPayWithCard"
              type="button"
              class="btn btn-blue py-4 text-center text-12"
              :callback="verifyPayment"
              :disabled="form.loading"
            >
              <sm-loader-white v-if="form.loading" />
              <template v-else>
                Make Payment {{ amountToPayInCurrency }}
              </template>
            </paystack>
          </template>
          <template v-else-if="paymentMethod === 'bank_transfer'">
            <button
              type="button"
              class="btn btn-blue py-4 text-center text-12"
              @click.prevent="showBankTransferModal"
              :disabled="form.loading"
            >
              <template v-if="form.loading">Verifying...</template>
              <template v-else>
                Make Payment {{ amountToPayInCurrency }}
              </template>
            </button>
          </template>
        </div>
      </div>
    </modal>
    <modal
      className="w-11/12 md:w-443px lg:w-443px flex flex-col rounded"
      ref="bankTransferModal"
    >
      <h4 class="text-xl xl:text-20 font-bold mb-4 text-left text-gray-700">
        Payment Information
      </h4>
      <!-- <form @submit.prevent="makeBankTransferRequest">
        <template v-if="getFormError(form)">
          <div class="alert alert-red-soft -mt-10 mb-10">
            <div class="alert-icon">
              !
            </div>
            <div class="text-xs font-normal">
              {{ getFormError(form) }}
            </div>
          </div>
        </template>
        <div class="grid grid-cols-2 gap-8">
          <div class="col-span-1">
            <form-group
              :left-icon="false"
              right-icon="copy-outline"
              :right-icon-click="() => copyText(amountToPay)"
              :disabled="true"
              v-model="amountToPayInCurrency"
            >
              To Pay
            </form-group>
          </div>
          <div class="col-span-1">
            <form-group
              :left-icon="false"
              right-icon="copy-outline"
              :right-icon-click="() => copyText(accountNumber)"
              :disabled="true"
              v-model="accountNumber"
            >
              Account Number
            </form-group>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-8">
          <div class="col-span-1">
            <form-group
              :left-icon="false"
              :form="form"
              right-icon="copy-outline"
              :right-icon-click="() => copyText(accountName)"
              :disabled="true"
              v-model="accountName"
            >
              Account Name
            </form-group>
          </div>
          <div class="col-span-1">
            <form-group
              :left-icon="false"
              right-icon="copy-outline"
              :right-icon-click="() => copyText(bankName)"
              :disabled="true"
              v-model="bankName"
            >
              Bank Name
            </form-group>
          </div>
        </div>
        <form-group
          :left-icon="false"
          right-icon="copy-outline"
          :right-icon-click="() => copyText(reference)"
          :disabled="true"
          v-model="reference"
        >
          Payment Reference
        </form-group>
        <div class="flex justify-center mt-3">
          <button
            type="button"
            class="btn btn-md btn-blue-outline px-12 text-12"
            @click="$refs.contactModal.open()"
          >
            Contact Support
          </button>
        </div> -->
      <!-- <div class="grid grid-cols-2 gap-2">
          <button
            type="submit"
            class="btn btn-md btn-blue w-full px-5 text-12"
            :disabled="form.loading"
          >
            <template v-if="form.loading">Verifying...</template>
            <template v-else>Mark as Paid</template>
          </button>
          <button
            type="button"
            class="btn btn-md btn-blue-outline w-full px-5 text-12"
            :disabled="form.loading"
          >
            <template v-if="form.loading">Verifying...</template>
            <template v-else>Contact Support</template>
          </button>
        </div> -->
      <!-- </form> -->

      <div class="pt-2 mt-4 pb-2">
        <div
          class="mb-4 -mt-4 pb-2 pt-2 w-full"
          @click.prevent="setTransferCollectionProvider('korapay')"
        >
          <korapay
            @confirmTransfer="confirmTransfer"
            :amount="amountToTransfer"
            :email="email"
            :payment-reference="payReference"
            narration="loan repayment with korapay transfer"
            ref="korapay"
          >
            <div
              class="w-full flex items-center justify-between bg-gray-200 px-6 py-4 rounded"
            >
              <img
                :src="svg.korapayLogo"
                width="80"
                height="40"
                alt="korapay"
              />
              <div class="flex justify-end">
                <div
                  v-if="transferCollectionProvider == 'korapay'"
                  class="flex justify-center items-center"
                >
                  <vector :src="svg.check" />
                </div>
                <p class="rounded-full h-5 w-5 bg-blue-200" v-else></p>
              </div>
            </div>
            <p class="text-12 font-normal text-blue-500 mt-2 text-left">
              A processing fee of ₦{{ korapayTransferFee | currency }} applies.
            </p>
          </korapay>
        </div>

        <div class="flex gap-4 mt-50 mb-4">
          <button
            type="button"
            class="btn btn-gray-outline btn-md w-full"
            @click.prevent="selectPaymentOption"
          >
            <span>Back</span>
          </button>
          <button
            type="button"
            class="btn btn-blue btn-md w-full"
            @click.prevent="initiateTransfer"
            :disabled="confirmTransferStatus"
          >
            <span v-if="confirmTransferStatus">Verifying...</span>
            <span v-else>Pay</span>
          </button>
        </div>
      </div>
    </modal>
    <!-- <modal
      className="w-11/12 md:w-443px lg:w-443px pt-24 px-24 text-center"
      ref="bankTranserSuccessModal"
    >
      <img :src="checkmark" class="mx-auto mb-8" height="90" alt="Check Mark" />
      <div class="text-lg font-bold mb-4">
        Transfer Request Recorded!
      </div>
      <div class="text-sm font-thin mb-20">
        Please wait while our support team approves your payment.
      </div>
      <button
        type="button"
        class="btn btn-blue-outline px-16"
        @click.prevent="$refs.bankTranserSuccessModal.close"
      >
        Close
      </button>
    </modal> -->

    <modal5 className="w-11/12 md:w-4/12 flex flex-col" ref="contactModal">
      <div class="text-lg font-bold mb-5">Support Details</div>
      <div class="text-sm font-thin mb-3">
        Email: <span class="font-bold">hello@credpal.com</span>
      </div>
      <div class="text-sm font-thin">
        Phone: <span class="font-bold">012296294</span>
      </div>
    </modal5>

    <modal
      className="w-11/12 md:w-443px lg:w-443px pt-24 px-24 text-center"
      ref="paymentSuccessModal"
    >
      <img
        :src="
          isRepaymentTransferPending ? assets.icons.lg.exclamation : checkmark
        "
        class="mx-auto mb-8"
        height="90"
        alt="Check Mark"
      />

      <div v-if="isRepaymentTransferPending">
        <div class="text-lg font-bold mb-4">
          Payment {{ repaymentTransferStatus }}
        </div>
        <div class="text-sm font-thin mb-20">
          {{ repaymentTransferMessage }}
        </div>
      </div>
      <div v-else>
        <div class="text-lg font-bold mb-4">Payment Successful!</div>
        <div class="text-sm font-thin mb-20">
          Your repayment has been marked as paid.
        </div>
      </div>
      <button
        type="button"
        class="btn btn-blue-outline px-16"
        @click.prevent="closeSuccessModal"
      >
        Close
      </button>
    </modal>

    <modal22
      className="w-11/12 sm:w-443px md:w-443px xl:w-443px rounded text-center pt-24 bg-white"
      ref="waitingModal"
    >
      <img
        :src="assets.icons.lg.exclamation"
        class="mx-auto mb-8"
        height="90"
        alt="Check Mark"
      />
      <div class="text-lg font-bold mb-4">
        Alert!
      </div>
      <div class="text-16 mb-10">
        Please Hold on while we confirm the transaction
      </div>
    </modal22>
  </div>
</template>
<script>
export default {
  props: {
    error: {
      type: Function,
      default: () => {}
    },
    paymentReference: {
      type: String,
      default: ""
    },
    repayment: {
      type: [Object, Array]
    }
  },
  mounted() {
    this.reference = this.paymentReference;
  },
  data() {
    return {
      checkmark: require("@/assets/checkmark-base.svg"),
      form: this.$options.basicForm([
        { name: "percentage", value: "100" },
        { name: "payment_method", value: "paystack" }
      ]),
      paymentOptions: [
        // {title: '25%', value: 25},
        // {title: '50%', value: 50},
        // {title: '75%', value: 75},
        { title: "100%", value: 100 }
      ],
      // paymentMethods: [
      //   { title: 'Card Payment via Paystack', value: 'paystack' },
      //   // { title: 'Card Payment via Flutterwave', value: 'rave' },
      //   { title: 'Bank Transfer', value: 'bank_transfer' },
      // ],
      paymentMethod: "paystack",
      percentage: "100",
      reference: "",
      svg: {
        korapayLogo: require("@/assets/korapay_logo.svg"),
        check: require("@/assets/check.svg")
      },
      transferCollectionProvider: "korapay",
      repaymentTransferStatus: "",
      repaymentTransferMessage: "",
      isRepaymentTransferPending: false,
      confirmTransferStatus: false
    };
  },
  computed: {
    accountName() {
      return "Crednet Technologies";
    },
    accountNumber() {
      return "0815690344";
    },
    bankName() {
      return "Access Bank";
    },
    email() {
      return this.user?.email;
    },
    korapayTransferFee() {
      return (
        this.$store.state.session?.configuration?.korapay_transfer_fee || 197.25
      );
    },
    amountDue() {
      var amount = 0;

      switch (this.repayment?.constructor) {
        case Array:
          amount = this.repayment.reduce(
            (last, repayment) => last + repayment.amount,
            0
          );
          break;
        case Object:
          amount = this.repayment.amount;
          amount += this.repayment.default_charge?.amount || 0;
          amount -= this.repayment.repayment_wallet?.total_paid || 0;
          amount -= this.repayment.totalProcessingAmount || 0;
          break;
        default:
          break;
      }

      return amount;
    },
    amountDueInCurrency() {
      return `₦ ${this.$options.filters.currency(this.amountDue)}`;
    },
    amountToPay() {
      return this.amountDue * (this.form.data.percentage.value / 100);
    },
    amountToPayInCurrency() {
      return `₦ ${this.$options.filters.currency(this.amountToPay)}`;
    },
    amountToTransfer() {
      return parseFloat(this.amountDue) + parseFloat(this.korapayTransferFee);
    },
    processingFee() {
      const percentage = 1.78 / 100;
      let amount = this.amountDue;
      if (percentage === 0) {
        return 0;
      }
      let totalFee = amount / (1 - percentage);

      if (totalFee >= 2500) {
        totalFee += 100;
      }
      const fee = parseFloat(totalFee - amount)
      if(fee > 2000) {
        return parseFloat(2000)
      }
      return fee;
    },
    amountToPayWithCard() {
      return parseFloat(this.amountDue + this.processingFee);
    },
    payReference() {
      return `RPY-${this.repayment?.id}`;
    }
  },
  methods: {
    close() {
      this.$refs.paymentModal.close();
      this.$refs.bankTransferModal.close();
    },
    open() {
      this.showPaymentModal();
    },
    showPaymentModal() {
      this.$refs.paymentModal.open();
    },
    showBankTransferModal() {
      this.$refs.paymentModal.close();
      this.$refs.bankTransferModal.open();
    },
    closeSuccessModal() {
      this.close();
      this.$refs.paymentSuccessModal.close();
    },
    setTransferCollectionProvider(provider) {
      this.paymentMethod = "transfer";
      this.transferCollectionProvider = provider;
    },
    initiateTransfer() {
      this.$refs.bankTransferModal.close();
      this.$refs.korapay.payKorapay();
    },
    selectPaymentOption() {
      this.$refs.bankTransferModal.close();
      this.$refs.paymentModal.open();
    },
    async makeBankTransferRequest() {
      this.form.loading = true;
      await this.$post({
        url: `${this.$baseurl}/staff/repayments/transfer/${this.user.company_id}`,
        headers: this.headers,
        data: {
          reference: this.paymentReference,
          repayment: this.repayment.id
        },
        success: () => {
          this.$emit("success");
          this.$refs.paymentModal.close();
          this.$refs.bankTransferModal.close();
          this.$refs.bankTranserSuccessModal.open();
        },
        error: error => {
          this.form.error = error;
        }
      });
      this.form.loading = false;
    },
    async verifyPayment(response) {
      const { reference } = response;

      var data, url;

      switch (this.repayment.constructor) {
        case Array:
          url = `${this.$baseurl}/staff/repayments/pay-many/${this.user.id}`;
          data = {
            reference,
            repayments: this.repayment.map(repayment => repayment.id)
          };
          break;
        case Object:
          url = `${this.$baseurl}/staff/repayments/pay/${this.user.id}`;
          data = {
            reference,
            repayment: this.repayment.id
          };
          break;
        default:
          // statements_def
          break;
      }

      this.form.loading = true;
      await this.$post({
        url,
        data,
        headers: this.headers,
        success: () => {
          this.$emit("success");
          this.$success({
            title: "Payment Successful!",
            body: "Your repayment has been marked as paid.",
            button: "Okay"
          });
          this.$refs.paymentModal.close();
          this.$refs.bankTransferModal.close();
        },
        error: error => {
          this.form.error = error;
        }
      });
      this.form.loading = false;
    },
    async confirmTransfer(transaction) {
      this.confirmTransferStatus = true;
      this.$refs.waitingModal.open();
      await this.sendRequest("personal.transfers.loanRepaymentTransfer", {
        data: {
          payment_method: "korapay",
          reference: transaction.reference,
          amount: transaction.amount - this.korapayTransferFee,
          repayment_id: this.repayment.id
        },
        success: async response => {
          this.$emit("success");
          this.$refs.paymentSuccessModal.open();
          if (response.data.data.transfer_status !== "success") {
            this.repaymentTransferStatus = response.data.data.transfer_status;
            this.repaymentTransferPending = true;
            this.repaymentTransferMessage = `Trasfer ${response.data.data.transfer_status}, please hold on`;
            return;
          }
        },
        error: () => {
          this.$refs.paymentSuccessModal.open();
          this.repaymentTransferStatus = true;
          this.repaymentTransferpending = "failed";
          this.repaymentTransferMessage = "Trasfer failed, please try again";
        }
      });
      this.confirmTransferStatus = false;
      this.$refs.waitingModal.close();
    }
  }
};
</script>
